.loading_space {
  padding-block: 17px 14px;
}

.kycDetails-info {
  &__documents {
    color: var(--color-primary-light, #0051cc);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  &__documents {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    &_inner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      &_title {
        color: #0051cc;
        font-size: 16px;
        font-weight: 600;
        word-wrap: break-word;
      }
    }
  }
  &__lines {
    flex: 1 1 0;
    height: 1px;
    background: #dfe3ec;
  }
  img {
    width: 100%;
  }
  &__documentView {
    &_inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 30px;
      padding: 12px 0;
      &_img {
        max-width: 280px;
        max-height: 200px;
        width: 100%;
        height: 100%;
        display: flex;
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          }
        }
      }
    }
  }
  .personal-details-container {
    &__selfiImgContainer {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: inline-flex;
    }
    &__selfiImg {
      width: 144px;
      height: 144px;
      position: relative;
      background: #e6e6e6;
      border-radius: 4px;
    }
    &__img {
      width: 100%;
      height: 100%;
      display: block;
    }
    &__matchPercentage {
      text-align: center;
      color: black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      word-wrap: break-word;
      padding: 4px;
    }
  }
}
.kyc-doc {
  border-radius: 8px;
  object-fit: contain;
  max-width: 550px !important;
  max-height: 500px !important;
  height: 400px !important;
  overflow: auto;
}

.kyc-document {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: var(--base-margin);
  overflow: auto;
}

.kycDetail-row {
  min-height: 30px;
  display: flex;
  line-height: 30px;
  .report-label {
    min-width: 192px;
    font-size: 12px;
    color: var(--color-text-100-light);
    text-transform: capitalize;
  }
  p {
    font-size: var(--font-size-2);
    line-height: var(--line-height-4);
    color: var(--color-text-70-light);
    width: 40%;
    overflow: hidden;
    text-transform: capitalize;
    margin-left: 24px;
    margin-bottom: 0px;
  }
  .percentage__confidence {
    width: 10%;
  }
}

.kyc-user-profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 12px 0;
  &__profile-image {
    width: 200px;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__placeholder-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__match-percentage {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    color: var(--color-black);
  }
}

.kycDetail-row {
  min-height: 30px;
  display: flex;
  line-height: 30px;

  &__basicInfoContainer {
    width: 350px;
    display: flex;
    gap: 12px;
  }

  .kyc-report-label-kycValue {
    color: #1d1e21;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    &__conclusion {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      &__dot {
        font-size: var(--font-size-1);
      }
    }
  }
  .report-label {
    min-width: 192px;
    font-size: 12px;
    color: var(--color-text-100-light);
    text-transform: capitalize;
  }
  .kyc-report-label {
    min-width: 270px;
    font-size: 12px;
    color: var(--color-text-100-light);
    text-transform: capitalize;
    &__key {
      flex: 1 1 0;
      color: #6c7489;
      font-size: 14px;
      font-weight: 400;
      word-wrap: break-word;
    }
  }
  .kycDetail--ssn-bold {
    font-weight: 600 !important;
    font-size: var(--font-size-3) !important;
    line-height: 30px !important;
    color: var(--color-header-label-dark) !important;
  }
  p {
    font-size: var(--font-size-2);
    line-height: var(--line-height-4);
    color: var(--color-text-70-light);
    width: 40%;
    overflow: hidden;
    text-transform: capitalize;
    margin-left: 24px;
    margin-bottom: 0px;
    a {
      display: block;
      text-decoration: underline !important;
      text-decoration-color: blue !important;
      cursor: pointer;
      text-transform: capitalize;
      height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__reject {
    width: 100% !important;
  }
}
.kycDetails-contain {
  background: var(--color-bg-100-light);
  border: 0.4px solid var(--color-input-border-light);
  border-radius: 16px;
  display: flex;
  gap: calc(var(--base-margin) * 1);
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  min-width: 560px;
  .form-icon-container {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #282a2e;
    font-size: 20px;
    font-weight: 600;
    .form-icon-profile-container {
      background-color: #2269d3;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__formIcon {
        color: #ffffff;
        font-size: 22px;
      }
      &_Recipient {
        background-color: #389f75;
      }
    }
    .accreditation-icon {
      background-color: #389f75 !important;
    }
    .fund-account-icon {
      background-color: purple !important;
    }
    .background-check-icon {
      background-color: #2269d3 !important;
    }
    .sign-agreement-icon {
      background-color: #2269d3 !important;
    }
  }
}

.kycDetails-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 8px 12px;
  min-width: 560px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid var(--color-border-light-100, #dfe3ec);
  button {
    margin: 0 calc(var(--base-margin) / 2);
  }
}
.kycDetails-body {
  width: 100%;
  padding: 16px;
}
.kycDetails-noDataFound {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    color: var(--color-text-60-light);
    margin-top: 12px;
  }
}
