@import "variables/colors.scss";
.input {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	&__group {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;
		height: 56px;
	}
	&__label {
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-text-70-light);
		text-transform: capitalize;
		font-weight: 500;
	}
	&__isRequired {
		color: var(--color-delete);
	}
	&__description {
		font-size: var(--font-size-2);
		color: var(--color-text-60-light);
	}
	&__text-field {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		background: var(--color-input-background);
		border: 1px solid var(--color-input-border-light);
		border-radius: calc(var(--base-border-radius) * 0.5);
		padding: calc(var(--base-padding) * 0.5);
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		&:focus-within {
			border: 1px solid var(--color-primary-light);
			transition: 0.2s ease-in all;
		}
		&--error {
			border-color: var(--color-error);
			background-color: hsla(0, 100%, 50%, 0.06);
			&:focus-within {
				border-color: var(--color-error);
				background-color: hsla(0, 100%, 50%, 0.06);
			}
		}
	}
	&__value-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	&__field {
		width: 100%;
		background-color: var(--color-transparent);
		border: none;
		outline: none;
		padding: var(--zero-padding) calc(var(--base-padding) * 0.5);
		&:disabled {
			cursor: not-allowed;
		}
	}
	&__delete {
		padding: var(--zero-padding) calc(var(--base-padding) * 0.25);
		cursor: pointer;
		font-weight: 600;
		opacity: 0.5;
	}

	&__view {
		padding: var(--zero-padding) calc(var(--base-padding) * 0.25);
		cursor: pointer;
		font-weight: 600;
		opacity: 0.5;
		font-size: 24px;
		&:hover {
			color: var(--color-black);
			opacity: 0.6;
		}
	}

	&__error {
		color: var(--color-error);
		font-size: var(--font-size-2);
		font-weight: 500;
	}
}

/* this will remove the increment and decrement icon from the number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/* This will make cursor pointer in input calender icon */
input::-webkit-calendar-picker-indicator {
	cursor: pointer;
}
