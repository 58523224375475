.tc_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 16px;
  width: calc(33.33% - 16px);
  min-height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  &__input {
    border-color: 1px solid #8d93a5;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
  }
}

.connector {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  right: -28px;
  font-size: 20px;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.send_button {
  min-width: 100px;
}
