@import "./variables/colors.scss";
@import "./variables/font-size.scss";
@import "./variables/padding.scss";
@import "./variables/border-radius.scss";
@import "./variables/margin.scss";
@import "./variables/colors";

@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Great+Vibes&family=Homemade+Apple&family=Inspiration&family=Kristi&family=Monsieur+La+Doulaise&family=Reenie+Beanie&family=Sacramento&family=Zeyada&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: poppins;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
}

:after,
:before {
  box-sizing: border-box;
}
// css used from bootstrap
body {
  margin: 0;
  line-height: 1.5;
  color: #495057;
  font-size: 0.9375rem;
  font-weight: 400;
  background-color: #fff;
  margin: 0;
  letter-spacing: 0.02rem;
}
