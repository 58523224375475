.Kyc-wrapper {
  width: 100%;
  padding: 16px 0;
  display: flex;
  gap: 16px;

  &_inner {
    width: calc(50% - 16px);
  }
}
